import { Container, Row, Col } from "react-bootstrap";
import Particle2 from "../Particle2";

function NotFound() {
  return (
    <section>
    <Container fluid className="discord-section" style={{ height: '100vh' }}>
    <Particle2 />

      <Container>
      <Row></Row>
        <Row>
          <Col md={12} className="home-about-description">
            <h1 style={{ fontSize: "5em" }}>
            Oops...
            </h1>
            <h1 style={{ fontSize: "2.6em" }}>
            You've went too far into <b className="purple">hyperspace</b>.
            </h1>
            <p className="home-about-body">
            The <b className="purple">page</b> you were looking for <b className="purple">is</b> located <b className="purple">somewhere else</b>.
            </p>
          </Col>

        </Row>
    </Container>
    </Container>
    </section>
  );
}

export default NotFound;